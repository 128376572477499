"use client";

import { useRouter } from "next/navigation";
import { useEffect } from "react";

import { getCookie } from "@/lib/Analytics/getCookie";

const NORWEGIAN_LOCALES = ["nb-no", "nn-no", "nb", "nn", "nb-sj", "no"];
const SESSION_STORAGE_KEY = "languageRedirectHandled";
const DEFAULT_LANG = "no";
const BOT_PATTERNS: RegExp[] = [
  /bot/i,
  /spider/i,
  /crawl/i,
  /APIs-Google/i,
  /AdsBot/i,
  /Googlebot/i,
  /mediapartners/i,
  /Yandex/i,
  /Baidu/i,
  /Bingbot/i,
  /facebookexternalhit/i,
  /LinkedInBot/i,
  /slurp/i,
];

const getAlternateLinks = () => {
  const alternateLinks = document.querySelectorAll('link[rel="alternate"]');
  return Array.from(alternateLinks).map((link) => ({
    href: link.getAttribute("href"),
    lang: link.getAttribute("lang"),
    hrefLang: link.getAttribute("hreflang"),
  }));
};

function resolveURLBasedOnLanguage(browserLang: string) {
  if (NORWEGIAN_LOCALES.includes(browserLang.toLowerCase())) {
    return { lang: "no", url: "https://www.rorostweed.no/" };
  }
  return { lang: "en", url: "https://www.rorostweed.com/" };
}

/**
 * Component that redirects the user to the appropriate domain and path based on the client browser language.
 * Features:
 * Component that redirects the user to the appropriate domain and path based on the client browser language.
 * Insert this wherever you want the redirect to happen.
 **/
export default function LanguageRedirect() {
  const router = useRouter();

  useEffect(() => {
    const handleLanguageRedirect = () => {
      try {
        /* check if we've already handled redirect this session */
        if (typeof window !== "undefined") {
          const hasRedirected = sessionStorage.getItem(SESSION_STORAGE_KEY);
          if (hasRedirected === "true") {
            return;
          }
        }

        const browserLang =
          (window.navigator && navigator.language.toLowerCase()) ||
          DEFAULT_LANG;

        /* do not redirect bots */
        try {
          const userAgent = window.navigator.userAgent.toLowerCase();
          if (BOT_PATTERNS.some((pattern) => pattern.test(userAgent))) {
            return;
          }
        } catch (error) {
          console.error("Error checking user agent:", error);
        }

        const url = window.location.href;
        const hostname = new URL(url).hostname;
        const hasSetLocale = Boolean(getCookie("NEXT_LOCALE"));

        /* locale is set manually - do not redirect */
        if (hasSetLocale) {
          return;
        }

        const tld = hostname.substring(hostname.lastIndexOf(".") + 1);
        const shouldBeOnNorwegianSite = NORWEGIAN_LOCALES.includes(
          browserLang.toLowerCase()
        );

        const isOnNorwegianSite = tld === "no";

        if (shouldBeOnNorwegianSite !== isOnNorwegianSite) {
          const newURL = resolveURLBasedOnLanguage(browserLang);
          const alternatePaths = getAlternateLinks();

          const alternatePath = alternatePaths?.find(
            (slug) => slug.lang === newURL.lang || slug.hrefLang === newURL.lang
          )?.href;

          if (!alternatePath) {
            return;
          }

          const searchParams = window.location.search;
          let finalURL: URL;
          try {
            finalURL = new URL(alternatePath + searchParams, newURL.url);
          } catch (error) {
            console.error("Error constructing redirect URL:", error);
            finalURL = new URL("/", newURL.url);
          }

          /* set redirection flag in redirect */
          if (typeof window !== "undefined") {
            try {
              sessionStorage.setItem(SESSION_STORAGE_KEY, "true");
            } catch (error) {
              console.error("Error setting session storage:", error);
            }
          }

          const inProduction =
            process.env.NEXT_PUBLIC_VERCEL_ENV === "production";

          if (inProduction) {
            router.replace(finalURL.toString());
          }
        }
      } catch (error) {
        if (typeof window !== "undefined") {
          try {
            sessionStorage.setItem(SESSION_STORAGE_KEY, "true");
          } catch (storageError) {
            console.error("Error setting session storage:", storageError);
          }
        }
      }
    };

    handleLanguageRedirect();
  }, [router]);

  return null;
}
