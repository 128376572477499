import ReactPlayer from "react-player";

import Skeleton from "@/components/Skeleton";
import useVideo from "@/lib/storyblok/Media/useVideo";
import type { StoryBlokMedia } from "@/types/storyblok";

import UI from "./UI";
import styles from "./video.module.scss";

interface VideoProps {
  media: StoryBlokMedia;
}
const VimeoElement = ({ media }: VideoProps) => {
  const video = useVideo(media);
  return <UI video={video} />;
};

const Video = ({ media }: VideoProps) => {
  if (media?.file?.includes(".mp4")) {
    return (
      <div className={styles.videoWrap}>
        <ReactPlayer
          url={media.file}
          playsinline
          autoPlay={true}
          controls={false}
          muted={true}
          playing
          className="react-player"
          loop={true}
          fallback={<Skeleton height="100%" />}
        />
      </div>
    );
  }
  return <VimeoElement media={media} />;
};

export default Video;
